import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom"
import "./gallery.css"
import { Context } from "../../context/context";
import axios from "axios";
import Button from "../button/button"

function Gallery() {
    const value = useContext(Context)
    const [data, setData] = useState()



    useEffect(() => {
        axios.get(`${value.url}/gallery`)
            .then(res => {
                setData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false


    return (
        <>
            <h2 className="page__title">Галерея</h2>
            <Link className="add-photo__link" to={"/add-photo"}>
                <Button type="update">
                    Добавить
                </Button>
            </Link>
            <div className="gallery__list">
                {
                    data.map((item, i) => (
                        <Link key={i} className="gallery-card" to={`/gallery/${item.id}`}>
                            <div className="gallery-card__info-wrapper">
                                <img className="gallery-card__image" src={item.img} alt={item.title} />
                                <p className="product-card__title">{item.title}</p>
                            </div>
                        </Link >
                    ))
                }
            </div>
        </>

    )
}

export default Gallery