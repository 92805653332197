import React, { useContext, useState, useEffect } from "react";
import "./productForm.css"
import Button from "../button/button";
import { Context } from "../../context/context";
import axios from "axios"


function ProductForm(props) {
    const [categoryData, setCategoryData] = useState()
    const [subcategoryData, setSubcategoryData] = useState()
    const value = useContext(Context)
    const [showModal, setShowModal] = useState(false)

    const isAddProductForm = props.product === undefined ? true : false


    const {
        id,
        active,
        name,
        name_uz,
        full_name,
        full_name_uz,
        category,
        status,
        price,
        discount,
        modele,
        description,
        description_uz,
        you_tube_url,
        size,
        link_3D,
        about_product,
        about_product_uz,
        item_img
    } = props.product || {}


    function openModal(e) {
        e.preventDefault()
        setShowModal(true)
    }
    function closeModal(e) {
        e.preventDefault()
        setShowModal(false)
    }

    useEffect(() => {
        axios.get(`${value.url}/category`)
            .then(res => {
                setCategoryData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        axios.get(`${value.url}/subcategory`)
            .then(res => {
                setSubcategoryData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!categoryData || !subcategoryData) return false



    const options = categoryData.map((categoryItem) => {
        const subcategoriesForCategory = subcategoryData.filter(subcategoryItem => subcategoryItem.category === categoryItem.id);

        const categoryOptions = [
            <option key={`category-${categoryItem.id}`} value={categoryItem.id}>
                {categoryItem.title}
            </option>
        ];

        subcategoriesForCategory.forEach((subcategoryItem) => {
            categoryOptions.push(
                <option key={subcategoryItem.id} value={`${subcategoryItem.category}${subcategoryItem.id}`}>
                    -- {subcategoryItem.title}
                </option>
            );
        });

        return categoryOptions;
    });

    return (
        <form className="product__form" method="POST" action={`${value.url}/${props.action}`} encType="multipart/form-data">
            {isAddProductForm ? <></> : <p>ID товара: {id}</p>}
            <input type="hidden" name="id" value={id} />
            <input type="hidden" name="redirectUrl" value={window.location.origin} />
            <p>Показать на сайте</p>
            <div className="radio-label__wrapper">
                <label className="radio-label" htmlFor="active-1">
                    Да
                    <input className="radio-input" id="active-1" name="active" type="radio" value={1} defaultChecked={active === 1 ? true : false} required />
                </label>
                <label className="radio-label" htmlFor="active-0">
                    Нет
                    <input className="radio-input" id="active-0" name="active" type="radio" value={0} defaultChecked={active === 0 ? true : false} required />
                </label>
            </div>
            <div className="product__form-content">
                <div className="product__form-input-wrapper--df">
                    <label className="label" htmlFor="name">
                        Название товара RU
                        <input className="product__form-input" id="name" type="text" name="name" defaultValue={name} required />
                    </label>
                    <label className="label" htmlFor="name_uz">
                        Название товара UZ
                        <input className="product__form-input" id="name_uz" type="text" name="name_uz" defaultValue={name_uz} required />
                    </label>
                </div>
                <div className="product__form-input-wrapper--df">
                    <label className="label" htmlFor="full_name">
                        Полное название товара RU
                        <input className="product__form-input" id="full_name" type="text" name="full_name" defaultValue={full_name} required />
                    </label>
                    <label className="label" htmlFor="full_name_uz">
                        Полное название товара UZ
                        <input className="product__form-input" id="full_name_uz" type="text" name="full_name_uz" defaultValue={full_name_uz} required />
                    </label>
                </div>
                <label className="label" htmlFor="price">
                    Цена
                    <input className="product__form-input" id="price" type="text" name="price" defaultValue={price} required />
                </label>
                <label className="label" htmlFor="category">
                    Категория
                    <select className="product__form-input" name="category" id="category" defaultValue={category}>
                        {options}
                    </select>
                </label>
                <label className="label" htmlFor="status">
                    Статус
                    <input className="product__form-input" id="status" type="text" name="status" defaultValue={status} required />
                </label>
                <label className="label" htmlFor="discount">
                    Скидка
                    <input className="product__form-input" id="discount" type="text" name="discount" defaultValue={discount} required />
                </label>
                <label className="label" htmlFor="modele">
                    Модель
                    <input className="product__form-input" id="modele" type="text" name="modele" defaultValue={modele} required />
                </label>
                <div className="product__form-input-wrapper--df">
                    <label className="label" htmlFor="description">
                        Описание RU
                        <textarea className="product__form-input product__form-textarea" name="description" id="description" defaultValue={description} required></textarea>
                    </label>
                    <label className="label" htmlFor="description_uz">
                        Описание UZ
                        <textarea className="product__form-input product__form-textarea" name="description_uz" id="description_uz" defaultValue={description_uz} required></textarea>
                    </label>
                </div>
                <label className="label" htmlFor="you_tube_url">
                    Ссылка с YouTube
                    <input className="product__form-input" id="you_tube_url" type="text" name="you_tube_url" defaultValue={you_tube_url} required />
                </label>
                <label className="label" htmlFor="size">
                    Размер
                    <input className="product__form-input" id="size" type="text" name="size" defaultValue={size} required />
                </label>
                <label className="label" htmlFor="link_3D">
                    3D
                    <input className="product__form-input" id="link_3D" type="text" name="link_3D" defaultValue={link_3D} required />
                </label>


                <div className="product__form-input-wrapper--df">
                    <label className="label" htmlFor="about_product_1">
                        Короткое описание 1 RU
                        <input className="product__form-input" id="about_product_1" type="text" name="about_product_1" defaultValue={(about_product && about_product[0]) || ''} />
                    </label>
                    <label className="label" htmlFor="about_product_1_uz">
                        Короткое описание 1 UZ
                        <input className="product__form-input" id="about_product_1_uz" type="text" name="about_product_1_uz" defaultValue={(about_product && about_product_uz[0]) || ''} />
                    </label>
                </div>
                <div className="product__form-input-wrapper--df">
                    <label className="label" htmlFor="about_product_2">
                        Короткое описание 2
                        <input className="product__form-input" id="about_product_2" type="text" name="about_product_2" defaultValue={(about_product && about_product[1]) || ''} />
                    </label>
                    <label className="label" htmlFor="about_product_2_uz">
                        Короткое описание 2
                        <input className="product__form-input" id="about_product_2_uz" type="text" name="about_product_2_uz" defaultValue={(about_product && about_product_uz[1]) || ''} />
                    </label>
                </div>
                <div className="product__form-input-wrapper--df">
                    <label className="label" htmlFor="about_product_3">
                        Короткое описание 3
                        <input className="product__form-input" id="about_product_3" type="text" name="about_product_3" defaultValue={(about_product && about_product[2]) || ''} />
                    </label>
                    <label className="label" htmlFor="about_product_3_uz">
                        Короткое описание 3
                        <input className="product__form-input" id="about_product_3_uz" type="text" name="about_product_3_uz" defaultValue={(about_product && about_product_uz[2]) || ''} />
                    </label>
                </div>
                <div className="product__form-input-wrapper--df">
                    <label className="label" htmlFor="about_product_4">
                        Короткое описание 4
                        <input className="product__form-input" id="about_product_4" type="text" name="about_product_4" defaultValue={(about_product && about_product[3]) || ''} />
                    </label>
                    <label className="label" htmlFor="about_product_4">
                        Короткое описание 4
                        <input className="product__form-input" id="about_product_4_uz" type="text" name="about_product_4_uz" defaultValue={(about_product && about_product_uz[3]) || ''} />
                    </label>
                </div>
                <div className="product__form-input-wrapper--df">
                    <label className="label" htmlFor="about_product_5">
                        Короткое описание 5
                        <input className="product__form-input" id="about_product_5" type="text" name="about_product_5" defaultValue={(about_product && about_product[4]) || ''} />
                    </label>
                    <label className="label" htmlFor="about_product_5">
                        Короткое описание 5
                        <input className="product__form-input" id="about_product_5_uz" type="text" name="about_product_5_uz" defaultValue={(about_product && about_product_uz[4]) || ''} />
                    </label>
                </div>



                <label className="label" htmlFor="img_1">
                    Изображение 1
                    <input className="product__form-input" id="img_1" name="img_1" type="file" />
                </label>
                <img className="product__form-image" src={(item_img && item_img[0]) || ''} alt={name} />
                <label className="label" htmlFor="img_2">
                    Изображение 2
                    <input className="product__form-input" id="img_2" name="img_2" type="file" />
                </label>
                <img className="product__form-image" src={(item_img && item_img[1]) || ''} alt={name} />
                <label className="label" htmlFor="img_3">
                    Изображение 3
                    <input className="product__form-input" id="img_3" name="img_3" type="file" />
                </label>
                <img className="product__form-image" src={(item_img && item_img[2]) || ''} alt={name} />
                <label className="label" htmlFor="img_4">
                    Изображение 4
                    <input className="product__form-input" id="img_4" name="img_4" type="file" />
                </label>
                <img className="product__form-image" src={(item_img && item_img[3]) || ''} alt={name} />
                <label className="label" htmlFor="img_5">
                    Изображение 5
                    <input className="product__form-input" id="img_5" name="img_5" type="file" />
                </label>
                <img className="product__form-image" src={(item_img && item_img[4]) || ''} alt={name} />



            </div>
            <div className={showModal ? "product__form-moadal-wrapper product__form-moadal-wrapper--active" : "product__form-moadal-wrapper"}>
                <div className="product__form-moadal">
                    <p className="product__form-moadal-text">Удалить этот товар?</p>
                    <div>
                        <Button type="update" handler={closeModal}>
                            Отмена
                        </Button>
                        <Button type="delete" name="button" value="delete">
                            Удалить
                        </Button>
                    </div>
                </div>
                <div className="product__form-moadal-bg" onClick={closeModal}></div>
            </div>
            <div>
                <Button type="update" name="button" value="update">
                    Сохранить
                </Button>
                {
                    isAddProductForm ? <></> : <Button type="delete" handler={openModal}>
                        Удалить
                    </Button>
                }

            </div>
        </form>
    )
}


export default ProductForm