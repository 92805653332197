import React, { useState, createContext } from "react";

export const Context = createContext()

function UserContext(props) {
    const url = "https://backend.ikromdecor.uz"
    // const url = "http://localhost:3002"

    const value = {
        url
    }


    return <Context.Provider value={value}>{props.children}</Context.Provider>
}

export default UserContext