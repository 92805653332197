import React from "react";
import "./button.css"


function Button(props) {

    function buttonType() {
        switch (props.type) {
            case "update":
                return "button primary-btn"
            case "delete":
                return "button delete-btn"
            case "submit":
                return "button submit-btn"
            default:
                return "button"
        }
    }

    return (
        <button onClick={props.handler} className={buttonType()} value={props.value} name={props.name}>
            {props.children}
        </button>
    )
}

export default Button