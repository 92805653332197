import React, { useContext, useState } from "react";
import "./login.css"
import axios from "axios";
import Button from "../button/button"
import { Context } from "../../context/context";



function Login(props) {
    const [formData, setFormData] = useState({
        login: '',
        password: ''
    });

    const value = useContext(Context)


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${value.url}/login`, formData);
            const responseData = response.data;

            if (responseData.status === "success") {
                localStorage.setItem("token", responseData.token);
                props.setLoggedIn(true)
            } else {
                alert("Неверный логин или пароль");
            }
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            alert("Произошла ошибка. Пожалуйста, попробуйте еще раз.");
        }
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    return (
        <div className="login-wrapper" >
            <form className="login-form" method="POST" onSubmit={handleSubmit}>
                <input className="login-input" name="login" type="text" placeholder="Логин" value={formData.login} onChange={handleChange} required />
                <input className="login-input" name="password" type="password" placeholder="Пароль" value={formData.password} onChange={handleChange} required />
                <Button type="update">Войти</Button>
            </form>
        </div>
    )
}


export default Login