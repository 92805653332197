import React, { useContext, useEffect, useState } from "react";
import "./vacancy.css"
import VacancyForm from "../vacancyForm/vacancyForm"
import { useParams } from "react-router-dom";
import axios from "axios";
import { Context } from "../../context/context";


function Vacancy() {
    const [data, setData] = useState()
    const params = useParams()
    const value = useContext(Context)


    useEffect(() => {
        axios.get(`${value.url}/vacancies?id=${params.id}`)
            .then(res => {
                setData(res.data[0]);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    return (
        <>
            <h2 className="page__title">Изменить</h2>
            <VacancyForm title={data.title} description={data.description} edit="true" action={"add-vacancy"} />
        </>
    )
}

export default Vacancy
