import React, { useContext, useEffect, useState } from "react";
import "./applications.css"
import { Context } from "../../context/context";
import axios from "axios";
import ApplicationsItem from "../applicationsItem/applicationsItem"


function Applications() {
    const value = useContext(Context)
    const [data, setData] = useState()

    useEffect(() => {
        axios.get(`${value.url}/product-requests`)
            .then(res => {
                setData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    return (
        <>
            <h2 className="page__title">Заявки</h2>
            <div className="applications__list">
                {data.map((item, i) => (
                    <ApplicationsItem key={i} item={item} />
                ))}
            </div>
        </>
    )
}

export default Applications