import React, { useContext } from "react"
import "./vacancyForm.css"
import Button from "../button/button"
import { useParams } from "react-router-dom"
import { Context } from "../../context/context";


function VacancyForm(props) {
    const params = useParams()
    const value = useContext(Context)


    return (
        <form className="add-vacancy__form" method="POST" action={`${value.url}/${props.action}`}>
            <label className="label" htmlFor="title">
                Заголовок
                <input className="add-vacancy__form-input" name="title" id="title" type="text" defaultValue={props.title} required />
            </label>
            <label className="label" htmlFor="description">
                Описание
                <textarea className="add-vacancy__form-textarea" name="description" id="description" cols="30" rows="10" defaultValue={props.description} required></textarea>
            </label>
            <input type="hidden" name="redirectUrl" value={window.location.origin + '/vacancies'} />
            <input type="hidden" name="edit" value={props.edit} />
            <input type="hidden" name="id" value={params.id} />
            <Button type="update" name="button" value="add">
                Добавить
            </Button>
            <Button type="delete" name="button" value="delete">
                Удалить
            </Button>
        </form>
    )
}

export default VacancyForm

