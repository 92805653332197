import React, { useContext, useEffect, useState } from "react";
import "./galleryPhoto.css"
import { useParams } from "react-router-dom";
import axios from "axios";
import { Context } from "../../context/context";
import GalleryForm from "../galleryForm/galleryForm";

function GalleryPhoto(props) {
    const [data, setData] = useState()
    const params = useParams()
    const value = useContext(Context)

    useEffect(() => {
        axios.get(`${value.url}/gallery?id=${params.id}`)
            .then(res => {
                setData(res.data[0]);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false


    return (
        <>
            <h2 className="page__title">Изменить</h2>
            <GalleryForm redirect="gallery" gallery={data} edit="true" action="add-gallery-photo" />
        </>
    )
}

export default GalleryPhoto