import React, { useContext, useEffect, useState } from "react";
import "./layout.css"
import Menu from "../components/menu/menu";
import Login from "../components/login/login";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Context } from "../context/context";


function Layout(props) {
    const value = useContext(Context)

    const [loggedIn, setLoggedIn] = useState(false)
    const location = useLocation();

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            axios.post(`${value.url}/login`, { token: storedToken })
                .then(response => {
                    if (response.data.status === "success") {
                        setLoggedIn(true)
                    }
                })
                .catch(error => {
                    localStorage.clear();
                });
        }
    }, []);

    return (
        <>
            {
                loggedIn ?
                    <div className="layout">
                        <Menu />
                        <main className="main">
                            {props.children}
                        </main>
                    </div> : localStorage.getItem('token') ? <></> : <Login setLoggedIn={setLoggedIn} />
            }

        </>
    )
}

export default Layout