import React, { useContext, useEffect, useState } from "react"
import "./categoryForm.css"
import Button from "../button/button"
import { Context } from "../../context/context";
import axios from "axios";


function CategoryForm(props) {
    const value = useContext(Context)
    const [data, setData] = useState()
    const [showModal, setShowModal] = useState(false)
    const isAddCategorytForm = props.category === undefined ? true : false


    const {
        id,
        title,
        title_uz,
        category,
        img
    } = props.category || {}

    useEffect(() => {
        axios.get(`${value.url}/category`)
            .then(res => {
                setData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    function openModal(e) {
        e.preventDefault()
        setShowModal(true)
    }
    function closeModal(e) {
        e.preventDefault()
        setShowModal(false)
    }

    return (
        <form className="category__form" method="POST" action={`${value.url}/${props.action}`} encType="multipart/form-data">
            <img className="category__form-img" src={img} alt={title} />

            {
                props.action === "add-subcategory" ?
                    <label className="label" htmlFor="category-select">
                        Добавить в категорию
                        <select className="category__form-input" name="category" id="category-select" defaultValue={category}>
                            {
                                data.map((item, i) => (
                                    <option key={i} value={item.id} >{item.title}</option>
                                ))
                            }
                        </select>
                    </label> : <></>
            }

            <label className="label" htmlFor="title">
                Заголовок
                <input className="category__form-input" name="title" id="title" type="text" defaultValue={title} required />
            </label>
            <label className="label" htmlFor="title_uz">
                Заголовок UZ
                <input className="category__form-input" name="title_uz" id="title_uz" type="text" defaultValue={title_uz} required />
            </label>
            <label className="label" htmlFor="img">
                Изображение
                <input className="category__form-input" id="img" name="img" type="file" />
            </label>
            <input type="hidden" name="redirectUrl" value={window.location.origin + "/" + props.redirect} />
            <input type="hidden" name="edit" value={props.edit} />
            <input type="hidden" name="id" value={id} />
            <div className={showModal ? "category__form-moadal-wrapper category__form-moadal-wrapper--active" : "category__form-moadal-wrapper"}>
                <div className="category__form-moadal">
                    <p className="category__form-moadal-text">Удалить этот товар?</p>
                    <div>
                        <Button type="update" handler={closeModal}>
                            Отмена
                        </Button>
                        <Button type="delete" name="button" value="delete">
                            Удалить
                        </Button>
                    </div>
                </div>
                <div className="category__form-moadal-bg" onClick={closeModal}></div>
            </div>
            <div>
                <Button type="update" name="button" value="update">
                    Сохранить
                </Button>
                {
                    isAddCategorytForm ? <></> : <Button type="delete" handler={openModal}>
                        Удалить
                    </Button>
                }

            </div>
        </form>
    )
}

export default CategoryForm

