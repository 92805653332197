import React from "react";
import "./applicationsItem.css"
import Button from "../button/button"


function ApplicationsItem(props) {
    return (
        <div className="application">
            <p className="application__id">Номер заявки: {props.item.id}</p>
            <div className="application__info-wrapper">
                <p className="application__info">ФИО: {props.item.full_name}</p>
                <p className="application__info">Контакты: {props.item.tel}</p>
                <p className="application__info">Дата: {props.item.date}</p>
            </div>
            {/* <Button type="delete">
                Удалить
            </Button> */}
        </div>
    )
}

export default ApplicationsItem