import React from "react";
import "./vacancyCard.css"
import Button from "../button/button"
import { Link } from "react-router-dom";



function VacancyCard(props) {
    return (
        <div className="vacancy">
            <p className="vacancy__title">{props.title}</p>
            <p className="vacancy__description">{props.description}</p>
            <Link to={`/vacancy/${props.id}`}>
                <Button type="update">
                    Изменить
                </Button>
            </Link>
            <Link to={`/job-applications/${props.id}`}>
                <Button type="update">
                    Заявки
                </Button>
            </Link>
        </div>
    )
}

export default VacancyCard