import React, { useContext, useEffect, useState } from "react";
import "./jobApplications.css"
import { useParams } from "react-router-dom";
import axios from "axios";
import ApplicationsItem from "../applicationsItem/applicationsItem";
import { Context } from "../../context/context";



function JobApplications(props) {
    const [data, setData] = useState()
    const params = useParams()
    const value = useContext(Context)

    useEffect(() => {
        axios.get(`${value.url}/job-applications?vacancy_id=${params.id}`)
            .then(res => {
                setData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    return (
        <>
            <h2 className="page__title">Заявки</h2>
            <div className="applications__list">
                {
                    data.length === 0 ? <p>Нет заявок</p> :
                        data.map((item, i) => (
                            <ApplicationsItem key={i} item={item} />
                        ))
                }
            </div>
        </>
    )
}

export default JobApplications