import React from "react";
import "./productCard.css"
import { Link } from "react-router-dom";


function ProductCard(props) {
    const {
        id,
        name,
        category,
        item_img,
        price
    } = props.product

    const priceNewFormat = new Intl.NumberFormat('ru-RU').format(price);

    return (
        <Link className="product-card" to={`/product/${id}`}>
            <div className="product-card__info-wrapper">
                <img className="product-card__image" src={item_img[0]} alt={name} />
                <div className="product-card__title-wrapper">
                    <p className="product-card__title">{name}</p>
                    <p className="product-card__category">{category}</p>
                </div>
            </div>
            <p className="product-card__price">{priceNewFormat} сум</p>
        </Link>

    )
}

export default ProductCard