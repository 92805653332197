import React, { useState } from "react";
import "./menu.css"
import { Link } from "react-router-dom";

function Menu() {
    const [showMenu, setShowMenu] = useState(false)
    function toggleMenu() {
        setShowMenu(!showMenu)
    }
    function closeMenu() {
        setShowMenu(false)
    }
    return (
        <>
            <button className="menu-btn" onClick={toggleMenu}>
                Меню
            </button>
            <div className={showMenu ? "menu menu--active" : "menu"}>
                <Link className="title" to={"/"} onClick={closeMenu}>Ikrom Decor</Link>
                <ul className="menu__list">
                    <li className="menu__item">
                        <Link className="menu__link" to={"/"} onClick={closeMenu}>Каталог</Link>
                    </li>
                    <li className="menu__item">
                        <Link className="menu__link" to={"/vacancies"} onClick={closeMenu}>Вакансии</Link>
                    </li>
                    <li className="menu__item">
                        <Link className="menu__link" to={"/applications"} onClick={closeMenu}>Заявки</Link>
                    </li>
                    <li className="menu__item">
                        <Link className="menu__link" to={"/category"} onClick={closeMenu}>Категории</Link>
                    </li>
                    <li className="menu__item">
                        <Link className="menu__link" to={"/subcategory"} onClick={closeMenu}>Подкатегории</Link>
                    </li>
                    <li className="menu__item">
                        <Link className="menu__link" to={"/gallery"} onClick={closeMenu}>Галерея</Link>
                    </li>
                </ul>
            </div>
        </>
    )
}
export default Menu