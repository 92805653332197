import React, { useContext, useEffect, useState } from "react";
import "./category.css"
import { useParams } from "react-router-dom";
import axios from "axios";
import { Context } from "../../context/context";
import CategoryForm from "../categoryForm/categoryForm";

function Category(props) {
    const [data, setData] = useState()
    const params = useParams()
    const value = useContext(Context)

    useEffect(() => {
        axios.get(`${value.url}/${props.category}?id=${params.id}`)
            .then(res => {
                setData(res.data[0]);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false


    return (
        <>
            <h2 className="page__title">Изменить</h2>
            <CategoryForm redirect={props.category} category={data} edit="true" action={`add-${props.category}`} />
        </>
    )
}

export default Category