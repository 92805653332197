import React from "react"
import "./addProduct.css"
import ProductForm from "../productForm/productForm"


function AddProduct() {
    return (
        <>
            <h2 className="page__title">Новый продукт</h2>
            <div className="product">
                <ProductForm action="add-product"/>
            </div>
        </>
    )
}

export default AddProduct