import React, { useContext, useEffect, useState } from "react";
import "./catalog.css"
import ProductCard from "../productCard/productCard";
import Button from "../button/button";
import { Link } from "react-router-dom";
import axios from "axios";
import { Context } from "../../context/context";



function Catalog() {
    const value = useContext(Context)
    const [data, setData] = useState()

    useEffect(() => {
        axios.get(`${value.url}/catalog`)
            .then(res => {
                setData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    let activeProductsCount = 0
    data.forEach(product => {
        if (product.active === 1) {
            activeProductsCount++
        }
    });

    return (
        <>
            <h2 className="page__title">Каталог</h2>
            <div className="catalog__products-count__wrapper">
                <p className="catalog__products-count">Колиество товаров: {data.length}</p>
                <span className="catalog__products-count__vertical-line"></span>
                <p className="catalog__products-count">Активных товаров: {activeProductsCount}</p>
            </div>
            <Link to={"/add-product"}>
                <Button type="update">
                    Добавить
                </Button>
            </Link>
            <div className="catalog__list">
                {
                    data.map((product, i) => (
                        <ProductCard key={i} product={product} />
                    ))
                }
            </div>
        </>
    )
}

export default Catalog