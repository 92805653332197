import React, { useContext } from "react";
import "./addCategory.css"
import CategoryForm from "../categoryForm/categoryForm";



function AddCategory(props) {

    return (
        <>
            <h2 className="page__title">Новая категория</h2>
            <CategoryForm redirect={props.category} edit="false" action={`add-${props.category}`} />
        </>
    )
}

export default AddCategory