import React, { useContext } from "react";
import "./addPhoto.css"
import GalleryForm from "../galleryForm/galleryForm";



function AddPhoto() {

    return (
        <>
            <h2 className="page__title">Новая картинка</h2>
            <GalleryForm redirect="gallery" edit="false" action="add-gallery-photo" />
        </>
    )
}

export default AddPhoto