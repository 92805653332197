import React from "react"
import "./addVacancy.css"
import VacancyForm from "../vacancyForm/vacancyForm"

function AddVacancy() {
    return (
        <>
            <h2 className="page__title">Новая вакансия</h2>
            <VacancyForm edit="false" action={"add-vacancy"} />
        </>
    )
}

export default AddVacancy

