import React, { useContext, useEffect, useState } from "react";
import "./vacancies.css"
import Button from "../button/button"
import { Link } from "react-router-dom";
import axios from "axios";
import VacancyCard from "../vacancyCard/vacancyCard";
import { Context } from "../../context/context";


function Vacancies() {
    const [data, setData] = useState()
    const value = useContext(Context)


    useEffect(() => {
        axios.get(`${value.url}/vacancies`)
            .then(res => {
                setData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    return (
        <>
            <h2 className="page__title">Вакансии</h2>
            <Link className="add-vacancy__link" to={"/add-vacancy"}>
                <Button type="update">
                    Добавить
                </Button>
            </Link>
            <div className="vacancies__list">
                {
                    data.map((vacancy, i) => (
                        <VacancyCard id={vacancy.id} title={vacancy.title} description={vacancy.description} key={i} />
                    ))
                }
            </div>
        </>
    )
}

export default Vacancies