import React, { useContext, useEffect, useState } from "react";
import "./product.css"
import { useParams } from "react-router-dom";
import axios from "axios";
import ProductForm from "../productForm/productForm";
import { Context } from "../../context/context";



function Product() {
    const [data, setData] = useState()
    const value = useContext(Context)


    const params = useParams()

    useEffect(() => {
        axios.get(`${value.url}/catalog?id=${params.id}`)
            .then(res => {
                setData(res.data[0]);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    return (
        <div className="product">
            <ProductForm product={data} action="edit-product" />
        </div>
    )
}

export default Product